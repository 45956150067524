import {trans} from 'trans-js'
import {computed} from "vue";
import { usePage } from '@inertiajs/vue3'
export default {
    install: (app, options) => {
        const page = usePage();
        const locale = computed(() => page.props?.locale)
        const configuration = computed(() => ({
            locale: locale.value,
            Lingua: options.Lingua
        }))

        const translationCallback = (key, replace, config = configuration) => trans('site.'+key, replace, false, {
            Lingua: configuration.value.Lingua,
            locale: configuration.value.locale
        })

        const translationWithPluralizationCallback = (key, replace, config = configuration) => trans('site.'+key, replace, true, {
            Lingua: configuration.value.Lingua,
            locale: configuration.value.locale
        })
        // const r = (key, replacements = {}) => {
        //     let translation = key; // Default to key if no translation found.

        //     try {
        //         const page = usePage();
        //         if (page.props.translations && page.props.translations[key]) {
        //             translation = page.props.translations[key];
        //         }
        //     } catch (error) {
        //         console.warn('Translation error:', error);
        //     }

        //     // Replace placeholders in translation.
        //     Object.keys(replacements).forEach(r => {
        //         translation = translation.replace(`:${r}`, replacements[r]);
        //     });

        //     return translation;
        // };

        app.config.globalProperties.__t = translationCallback;

        app.mixin({
            methods: {
                __t: translationCallback,
            },
        });

        app.provide('__t', translationCallback);
    }
};
