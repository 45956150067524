import themeConfig from "@themeConfig";
// import '@mdi/font/css/materialdesignicons.css'
import { ar, en } from "vuetify/locale";
// import { mdi } from 'vuetify/iconsets/mdi'

const light = {
    dark: false,
    colors: {
        // primary: "#f0ae00",
        // info: "#137100",
        // success: "#05b187",
        // accent: "#fc4b6c",
        // warning: "#fec90f",
        // error: "#fc4b6c",
        // secondary: "#f0ae00",
        primary: "#5F5FFF",
        "primary-dark": "#363FAD",
        secondary: "#FFC500",
        "on-secondary": "#fff",
        "whatsapp" : "#09C72D",
        "whatsapp-background" : "#F5FFF9",
        "whatsapp-card" : "#FBFFFC",
        success: "#137100",
        info: "#16B1FF",
        warning: "#FFB400",
        error: "#FF5B64",
        "shadow": "#dcdcdc",
        "on-primary": "#FFFFFF",
        "on-success": "#FFFFFF",
        "on-warning": "#FFFFFF",
        'background': "#F4F5FA",
        "on-background": "#3A3541",
        "land-background": "#F5F5FE",
        "on-surface": "#3A3541",
        "grey-50": "#FAFAFA",
        "grey-100": "#F5F5F5",
        "grey-200": "#EEEEEE",
        "grey-300": "#E0E0E0",
        "grey-400": "#BDBDBD",
        "grey-500": "#9E9E9E",
        "grey-600": "#757575",
        "grey-700": "#616161",
        "grey-800": "#424242",
        "grey-900": "#212121",
        "no-answer": "#4945FF",
        "no-answer-base": "#DFDEFF",
        "follow-up": "#FF7A00",
        "follow-up-base": "#FFE7D1",
        "interested": "#1E6D08",
        "interested-base": "#D2E2CE",
        "not-interested-base": "#FFEFEF",
        "not-interested": "#FF5B64",
        "table-background" : "#F7F7F8",
        'completed': '#BDFECF',
        "active-title": "#363FAD",
        "border": "#a3a3a3",
        "input-background": "#f6f6f6"
    },
    variables: {
        "border-color": "#3A3541",
        "medium-emphasis-opacity": 0.68,

        // Shadows
        "shadow-key-umbra-opacity": "rgba(var(--v-theme-on-surface), 0.08)",
        "shadow-key-penumbra-opacity": "rgba(var(--v-theme-on-surface), 0.12)",
        "shadow-key-ambient-opacity": "rgba(var(--v-theme-on-surface), 0.04)",
    },
};
const dark = {
    dark: true,
    colors: {
        // primary: "#f0ae00",
        // info: "#137100",
        // success: "#05b187",
        // accent: "#fc4b6c",
        // warning: "#fec90f",
        // error: "#fc4b6c",
        // secondary: "#f0ae00",
        primary: "#5F5FFF",
        "primary-dark": "#363FAD",
        secondary: "#FFC500",
        "whatsapp" : "#09C72D",
        "whatsapp-background" : "#F5FFF9",
        "whatsapp-card" : "#FBFFFC",
        "on-secondary": "#fff",
        success: "#137100",
        info: "#16B1FF",
        warning: "#FFB400",
        error: "#FF5B64",
        "shadow": "#3d3d3d",
        "on-primary": "#FFFFFF",
        "on-success": "#FFFFFF",
        "on-warning": "#FFFFFF",
        background: "#161616",
        "on-background": "#F6F6F5",
        surface: "#0D0D0D",
        "on-surface": "#fff",
        "grey-50": "#2A2E42",
        "grey-100": "#2F3349",
        "grey-200": "#4A5072",
        "grey-300": "#5E6692",
        "grey-400": "#7983BB",
        "grey-500": "#8692D0",
        "grey-600": "#AAB3DE",
        "grey-700": "#B6BEE3",
        "grey-800": "#CFD3EC",
        "grey-900": "#E7E9F6",
        "no-answer": "#4945FF",
        "no-answer-base": "#DFDEFF",
        "follow-up": "#FF7A00",
        "follow-up-base": "#FFE7D1",
        'interested': "#1E6D08",
        "interested-base": "#D2E2CE",
        "not-interested-base": "#FFEFEF",
        "not-interested": "#FF5B64",
        "table-background" : "#0D0D0D",
        'completed': '#BDFECF',
        "active-title": "#5F5FFF",
        "border": "#a3a3a3",
        "input-background": "#f6f6f6"
    },
    variables: {
        "border-color": "#E7E3FC",
        "medium-emphasis-opacity": 0.68,

        // Shadows
        "shadow-key-umbra-opacity": "rgba(20, 18, 33, 0.08)",
        "shadow-key-penumbra-opacity": "rgba(20, 18, 33, 0.12)",
        "shadow-key-ambient-opacity": "rgba(20, 18, 33, 0.04)",
    },
};

const setting = {

    ssr: !!import.meta.env.SSR,
    locale: {
        defaultLocale: "en",
        fallbackLocale: "ar",
        messages: { ar, en },
    },
    theme: {
        cspNonce: "dQw4w9WgXcQ",
        defaultTheme: "dark",
        themes: {
            light,
            dark,
        },
        dark:
            typeof window !== "undefined"
                ? localStorage.getItem("3kode-active-theme") === null
                    ? themeConfig.value.app.isDark
                    : localStorage.getItem("3kode-active-theme") === "dark"
                : true,
    },

    icons: {
        defaultSet: 'mdi',

        // sets: {
        //     mdi
        // },
    },
    defaults: {
        VBtn: {
            color: "primary",
            rounded: "lg",
            flat: true,
            fontWeight: "400",
            letterSpacing: "0",
        },
        VAlert: {
            density: "comfortable",
        },
        VAvatar: {
            // ℹ️ Remove after next release
            variant: "flat",
        },
        VBadge: {
            // set v-badge default color to primary
            color: "primary",
        },

        VChip: {
            elevation: 0,
        },
        VMenu: {
            VList: {
                density: "compact",
            },
        },
        VPagination: {
            activeColor: "primary",
            density: "comfortable",
        },
        VTabs: {
            // set v-tabs default color to primary
            color: "primary",
        },
        VTooltip: {
            // set v-tooltip default location to top
            location: "top",
        },

        // VList: {
        //   VListItem: {
        //     activeColor: 'primary',
        //   },
        // },
        VCheckbox: {
            // set v-checkbox default color to primary
            color: "primary",
            density: "comfortable",
            // hideDetails: 'auto',
        },
        VRadioGroup: {
            color: "primary",
            density: "comfortable",
            // hideDetails: 'auto',
        },
        VRadio: {
            density: "comfortable",
            // hideDetails: 'auto',
        },
        VSelect: {
            variant: "outlined",
            density: "comfortable",
            color: "primary",
            // hideDetails: 'auto',
        },
        VRangeSlider: {
            // set v-range-slider default color to primary
            color: "primary",
            density: "comfortable",
            thumbLabel: true,
            // hideDetails: 'auto',
        },
        VRating: {
            // set v-rating default color to primary
            color: "warning",
        },
        VProgressCircular: {
            // set v-progress-circular default color to primary
            color: "primary",
        },
        VSlider: {
            // set v-slider default color to primary
            color: "primary",
            // hideDetails: 'auto',
        },
        VTextField: {
            variant: "outlined",
            density: "comfortable",
            color: "primary",
            // hideDetails: 'auto',
        },
        VAutocomplete: {
            variant: "outlined",
            density: "comfortable",
            color: "primary",
            // hideDetails: 'auto',
        },
        VCombobox: {
            variant: "outlined",
            density: "comfortable",
            color: "primary",
            // hideDetails: 'auto',
        },
        VDialog: {
            scrollStrategy: "none",

            // hideDetails: 'auto',
        },
        VFileInput: {
            variant: "outlined",
            density: "comfortable",
            color: "primary",
            // hideDetails: 'auto',
        },
        VTextarea: {
            variant: "outlined",
            density: "comfortable",
            color: "primary",
            // hideDetails: 'auto',
        },
        VSwitch: {
            // set v-switch default color to primary
            color: "primary",
            // hideDetails: 'auto',
        },
        VCard: {
            flat: true,
            elevation: 10,
        },
    },
};
export default setting;
