import { usePage } from '@inertiajs/vue3'

export function useTranslate() {

    const __t = (key, replacements = {}) => {
        let translation = ""
        translation = usePage().props.translations[key] || key;
        Object.keys(replacements).forEach(r => {
            translation = translation.replace(`:${r}`, replacements[r]);
        });
        return translation;

    }

    return { __t }
}
