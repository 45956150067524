// import _ from 'lodash';

// window._ = _;
import { useToast } from "vue-toastification";
import { useTranslate } from '@/Composable/Translate';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
const $toast = useToast();
const { __t } = useTranslate();
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// window.axios.defaults.headers.common['Accept'] = 'application/json';
window.axios.defaults.withCredentials = true;

window.axios.defaults.headers.common['Accept-Language'] = document.documentElement.lang;
// axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`

window.axios.interceptors.response.use(r => r, e => {
    if (e.response?.status == 423) {
        $toast.warning(__t('demo_mode'));
    } else {
        console.error(e)
    }

    return Promise.reject(e)
})

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({

//         broadcaster: "pusher",
//         key: import.meta.env.VITE_PUSHER_APP_KEY,
//         cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//         forceTLS: true,
//         disableStats: true,
//         encrypted: true,
//         debug: true,
//     // broadcaster: 'pusher',
//     // debug: true,
//     // key: import.meta.env.VITE_PUSHER_APP_KEY,
//     // wsHost: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     // wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     // wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     // cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//     // encrypted: false,
//     // forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     // enabledTransports: ['ws', 'wss'],



//     // broadcaster: 'pusher',
//     // key: process.env.MIX_PUSHER_APP_KEY,
//     // wsHost: window.location.hostname,
//     // wsPort: 6001,
//     // wssPort: 6001,
//     // disableStats: true,
//     // encrypted: true,
//     // forceTLS: true,
//     // useTLS: true,
//     // debug: true,
//     // enabledTransports: ['ws', 'wss']
// });
